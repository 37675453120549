import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAboutUs } from '../actions/MainAction';
import Slider from 'react-slick';
import AboutUs from './cards/AboutUs'
import PrewSvg from './icons/PrewSvg';
import NextSvg from './icons/NextSvg';

export class MnPhotoSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: 500, // Varsayılan hız
      isDotClicked: false, // Dot tıklanıp tıklanmadığını takip etmek için bir flag
    };
    this.sliderRef = React.createRef(); // Slider referansı
  }

  // Dot tıklamalarını algılamak
  handleDotClick = () => {
    this.setState({ speed: 200, isDotClicked: true }); // Dot tıklandığında hızı 300 yap
  };

  // Slider değişiminden önce hız kontrolü
  handleBeforeChange = () => {
    const { isDotClicked } = this.state;
    if (!isDotClicked) {
      this.setState({ speed: 500 }); // Dot tıklanmadıysa varsayılan hız 2000
    }
  };

  // Geçiş sonrası isDotClicked'i sıfırla
  handleAfterChange = () => {
    this.setState({ isDotClicked: false }); // Geçiş tamamlandığında flag sıfırlanır
  };


  componentDidMount() {
    this.props.getAboutUs();
  }

  render() {
    const { speed } = this.state;
    const settings = {
      dots: true,
      infinite: true, // Döngüsel yapıyoruz
      speed: 500, // Geçiş hızı
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      centerMode: true, // Merkezi düzenleme etkin
      centerPadding: "0px", // Kenarlardaki kayma boşluğu
      arrows: false,
      swipeToSlide: true,
      beforeChange: this.handleBeforeChange, // Geçiş öncesinde hızı ayarla
      afterChange: this.handleAfterChange,
      focusOnSelect: true, // Seçilen slaytı merkeze al
      responsive: [
        {
          breakpoint: 2650,
          settings: {
            slidesToShow: 2,
            centerPadding: "23%",
          },
        },
        {
          breakpoint: 2420,
          settings: {
            slidesToShow: 2,
            centerPadding: "22%",
          },
        },
        {
          breakpoint: 2309,
          settings: {
            slidesToShow: 2,
            centerPadding: "20%",
          },
        },
        {
          breakpoint: 2159,
          settings: {
            slidesToShow: 2,
            centerPadding: "18%",
          },
        },
        {
          breakpoint: 2025,
          settings: {
            slidesToShow: 2,
            centerPadding: "16%",
          },
        },
        {
          breakpoint: 1912,
          settings: {
            slidesToShow: 2,
            centerPadding: "14%",
          },
        },
        {
          breakpoint: 1809,
          settings: {
            slidesToShow: 2,
            centerPadding: "12%",
          },
        },
        {
          breakpoint: 1699,
          settings: {
            slidesToShow: 2,
            centerPadding: "10%",
          },
        },
        {
          breakpoint: 1616,
          settings: {
            slidesToShow: 2,
            centerPadding: "7%",
          },
        },
        {
          breakpoint: 1506,
          settings: {
            slidesToShow: 2,
            centerPadding: "5%",
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1,
            centerPadding: "25%",
          },
        },
        {
          breakpoint: 1303,
          settings: {
            slidesToShow: 1,
            centerPadding: "23%",
          },
        },
        {
          breakpoint: 1193,
          settings: {
            slidesToShow: 1,
            centerPadding: "21%",
          },
        },
        {
          breakpoint: 1117,
          settings: {
            slidesToShow: 1,
            centerPadding: "18%",
          },
        },
        {
          breakpoint: 1010,
          settings: {
            slidesToShow: 1,
            centerPadding: "21%",
          },
        },
        {
          breakpoint: 942,
          settings: {
            slidesToShow: 1,
            centerPadding: "26%",
          },
        },
        {
          breakpoint: 892,
          settings: {
            slidesToShow: 1,
            centerPadding: "23%",
          },
        },
        {
          breakpoint: 796,
          settings: {
            slidesToShow: 1,
            centerPadding: "21%",
          },
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            centerPadding: "18%",
          },
        },
        {
          breakpoint: 682,
          settings: {
            slidesToShow: 1,
            centerPadding: "23%",
          },
        },
        {
          breakpoint: 648,
          settings: {
            slidesToShow: 1,
            centerPadding: "20%",
          },
        },
        {
          breakpoint: 578,
          settings: {
            slidesToShow: 1,
            centerPadding: "17%",
          },
        },
        {
          breakpoint: 532,
          settings: {
            slidesToShow: 1,
            centerPadding: "14%",
          },
        },
        {
          breakpoint: 484,
          settings: {
            slidesToShow: 1,
            centerPadding: "18%",
          },
        },
        {
          breakpoint: 452,
          settings: {
            slidesToShow: 1,
            centerPadding: "14%",
          },
        },
        {
          breakpoint: 404,
          settings: {
            slidesToShow: 1,
            centerPadding: "11%",
          },
        },
        {
          breakpoint: 368,
          settings: {
            slidesToShow: 1,
            centerPadding: "8%",
          },
        },
        {
          breakpoint: 340,
          settings: {
            slidesToShow: 1,
            centerPadding: "6%",
          },
        },
        {
          breakpoint: 324,
          settings: {
            slidesToShow: 1,
            centerPadding: "4%",
          },
        },

      ],
    };

    const { aboutUsData } = this.props;

    return (
      <div className="pt-[40px]">
        <div
          onClick={(e) => {
            if (e.target.closest('.slick-dots')) {
              this.handleDotClick(); // Eğer dot tıklandıysa
            }
          }}
        >
          <div className="relative">
            <Slider
              {...settings}
              ref={this.sliderRef}
            >
              {aboutUsData?.map((data, i) => (
                <AboutUs key={i} image={data?.image_full_url} />
              ))}
            </Slider>

            <button
              className="absolute top-[50%] left-[68px] max-[900px]:left-[26px] z-[979] transform -translate-y-[50%] p-[14px] bg-[#666] rounded-[50%] max-[700px]:hidden"
              onClick={() => this.sliderRef.current.slickPrev()}
            >
              <PrewSvg />
            </button>

            <button
              className="absolute top-[50%] right-[68px] z-[979] max-[900px]:right-[26px] transform -translate-y-[50%] p-[14px] bg-[#666] rounded-[50%] max-[700px]:hidden"
              onClick={() => this.sliderRef.current.slickNext()}
            >
              <NextSvg />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  aboutUsData: state.Data.aboutUsData,
})

const mapDispatchToProps = { getAboutUs }

export default connect(mapStateToProps, mapDispatchToProps)(MnPhotoSlider)