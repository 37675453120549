import React, { Component } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './pages/MainPage'
import Header from './components/Header/Header'
import Footer from './components/Header/Footer'
import Menu from './pages/Menu';
import Company from './pages/Company';
import { getContent } from './actions/MainAction';
import { connect } from 'react-redux';
import ScrollToTop from './components/ScrollToTop';

export class App extends Component {
  componentDidMount() {
    const langCode = localStorage.getItem('langCode');
    if (langCode === 'az') {
      this.props.getContent(1);
    } else {
      this.props.getContent(2);
    }

  }


  getLangCode = () => {
    const langCode = localStorage.getItem('langCode');// Dili buradan alıyoruz
    return langCode === 'az' ? '/az' : '/ru';
  };

  render() {
    const { contentData } = this.props;
    const langCodeId = this.getLangCode();

    return (
      <div >
        <BrowserRouter>
          <Header />
          <ScrollToTop>
            <Routes>
              <Route path={`${langCodeId}/`} element={<MainPage />} />
              <Route path={`${langCodeId}/menu`} element={<Menu />} />
              <Route path={`${langCodeId}/company`} element={<Company />} />
            </Routes>
          </ScrollToTop>
          <Footer />
        </BrowserRouter>

      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData
})

const mapDispatchToProps = { getContent };
export default connect(mapStateToProps, mapDispatchToProps)(App)