import React, { Component } from 'react'

export class FacebookSvg20 extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                fill="none"
            >
                <path
                    fill="#000"
                    d="M10 1.875A8.125 8.125 0 1 0 18.125 10 8.133 8.133 0 0 0 10 1.875Zm.625 14.971v-4.971H12.5a.624.624 0 1 0 0-1.25h-1.875V8.75a1.25 1.25 0 0 1 1.25-1.25h1.25a.625.625 0 1 0 0-1.25h-1.25a2.5 2.5 0 0 0-2.5 2.5v1.875H7.5a.625.625 0 1 0 0 1.25h1.875v4.971a6.875 6.875 0 1 1 1.25 0Z"
                />
            </svg>
        )
    }
}

export default FacebookSvg20