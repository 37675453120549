import React, { Component } from 'react'

export class MenuButton extends Component {
  render() {
    const { icons, title, id, onClick } = this.props;
    return (
      <button onClick={() => onClick(id)} className='p-[16px_32px] bg-[#fff] rounded-[8px] flex items-center gap-[12px] max-[900px]:p-[12px_20px] m-0'>
        <img src={icons} className='w-[24px] h-[24px]' />
        <h3 className='font-[600] text-[16px] leading-[20px] text-[#000]'>
          {title}
        </h3>
      </button>
    )
  }
}




{/* <header className='w-full h-[400px] flex flex-col justify-center items-center relative'>
  {menuImageData?.map((data, i) => (
    <div className='w-full h-[400px] overflow-hidden flex justify-center items-center relative' key={i}>
      <img src={data?.image_full_url} className='h-[400px] w-full object-cover' />

      <div className='absolute inset-0 flex flex-wrap justify-center items-center content-center gap-[16px]'>
        {catagoryData?.map((cat, j) => {
          const langCode = localStorage.getItem('langCode');
          return (
            <MenuButton
              key={j}
              id={cat?.id}
              icons={cat?.icon_dark}
              title={langCode === 'az' ? cat?.title : cat?.title_en}
              onClick={() => this.scrollToSection(cat?.id)}
            />
          );
        })}
      </div>
    </div>
  ))}
</header> */}

export default MenuButton