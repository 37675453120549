import React, { Component } from 'react';

export class PrewSvg extends Component {
    state = {
        width: 28, // default width
        height: 28, // default height
    };

    updateDimensions = () => {
        if (window.innerWidth <= 480) {
            this.setState({ width: 20, height: 20 });
        } else {
            this.setState({ width: 28, height: 28 });
        }
    };

    componentDidMount() {
        this.updateDimensions(); // Set initial size
        window.addEventListener('resize', this.updateDimensions); // Listen for window resizing
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions); // Clean up listener
    }

    render() {
        const { width, height } = this.state;
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width={width}
                height={height}
                viewBox="0 0 512.006 512.006"
                fill="#fff"
            >
                <path d="M388.419 475.59 168.834 256.005 388.418 36.421c8.341-8.341 8.341-21.824 0-30.165s-21.824-8.341-30.165 0L123.586 240.923c-8.341 8.341-8.341 21.824 0 30.165l234.667 234.667a21.275 21.275 0 0 0 15.083 6.251 21.277 21.277 0 0 0 15.083-6.251c8.341-8.341 8.341-21.824 0-30.165z" />
            </svg>
        );
    }
}

export default PrewSvg;
