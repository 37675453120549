import React, { Component } from 'react'
import { connect } from 'react-redux'
import CompanyCard from '../components/cards/CompanyCard'
import { getCompany } from '../actions/MainAction'
import CallButton from '../components/cards/CallButton'
import MenuBUtton from '../components/cards/MenuButton'

export class Company extends Component {
    componentDidMount() {
        this.props.getCompany();
        window.scrollTo(0, 0);
    }
    render() {
        const { companyData, contentData } = this.props;
        // console.log(companyData);

        return (
            <div className='pt-[47px] container'>
                <div className='w-full pt-[20px] max-[1020px]:w-full'>
                    <div className='p-[0px_16px] border-b-[1px] border-b-[#000] w-full '>
                        <h3 className='font-[600] text-[32px] leading-[40px] text-[#000] pb-[12px] max-[670px]:text-center'>{contentData?.company}</h3>
                    </div>

                    <div className='grid grid-cols-4 gap-[36px] max-[670px]:flex-col max-[1170px]:grid-cols-3 max-[900px]:grid-cols-2 max-[600px]:grid-cols-1'>
                        {companyData?.map((data, i) => {
                            const langCode = localStorage.getItem('langCode');
                            return (
                                <CompanyCard
                                    key={i}
                                    title={langCode === 'az' ? data?.title : data?.title_en}
                                    description={langCode === 'az' ? data?.description : data?.description_en}
                                    phone_number={data?.phone_number}
                                    home_number={data?.home_number}
                                    phone_number3={data?.phone_number3}
                                    clock={langCode === 'az' ? data?.clock : data?.clock_en}
                                    clock_2={langCode === 'az' ? data?.clock_2 : data?.clock_2_en}
                                    iframe={data?.location_iframe}
                                />

                            )
                        })

                        }
                    </div>
                </div>
                <MenuBUtton />
                <CallButton />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    companyData: state.Data.companyData,
    contentData: state.Data.contentData,
})

const mapDispatchToProps = { getCompany }

export default connect(mapStateToProps, mapDispatchToProps)(Company)