import React, { Component } from 'react';

export class NextSvg extends Component {
    state = {
        width: 28, // default width
        height: 28, // default height
    };

    updateDimensions = () => {
        if (window.innerWidth <= 480) {
            this.setState({ width: 20, height: 20 });
        } else {
            this.setState({ width: 28, height: 28 });
        }
    };

    componentDidMount() {
        this.updateDimensions(); // Set initial size
        window.addEventListener('resize', this.updateDimensions); // Listen for window resizing
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions); // Clean up listener
    }

    render() {
        const { width, height } = this.state;
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width={width}
                height={height}
                viewBox="0 0 512.005 512.005"
                fill="#fff"
            >
                <path d="M388.418 240.923 153.751 6.256c-8.341-8.341-21.824-8.341-30.165 0s-8.341 21.824 0 30.165L343.17 256.005 123.586 475.589c-8.341 8.341-8.341 21.824 0 30.165a21.275 21.275 0 0 0 15.083 6.251 21.277 21.277 0 0 0 15.083-6.251l234.667-234.667c8.34-8.34 8.34-21.823-.001-30.164z" />
            </svg>
        );
    }
}

export default NextSvg;
