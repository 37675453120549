import React, { Component } from 'react'

export class Card1 extends Component {
    render() {
        const { products_title, products_image, weight, description, price } = this.props;
        return (
            // <div className='flex flex-col gap-[12px] max-w-[300px] h-auto '>
            //     <div className='flex justify-center'>
            //         <div className='flex justify-center items-center max-w-[244px] h-[72px]'>
            //             <p className='font-[600] text-[20px] max-[500px]:text-[16px] leading-[24px] text-[#000] text-center'>
            //                 {products_title}
            //             </p>
            //         </div>
            //     </div>

            //     <div className='relative'>

            //         <img src={products_image} className='rounded-[8px] w-[300px]  h-[400px] max-[1000px]:h-[350px] max-[870px]:h-[270px] max-[500px]:h-[243px] max-[410px]:h-[200px]' />
            //         <span className='absolute bottom-[16px] left-[16px] bg-[#fff] p-[8px_16px] font-[300] text-[12px] leading-[20px] rounded-[4px] max-[500px]:p-[6px_12px]'>
            //             {weight}
            //         </span>
            //     </div>

            //     <div className='p-[0px_16px] h-[80px] flex justify-center font-[400] text-[14px] leading-[20px] text-[#8D8D8D] text-center' dangerouslySetInnerHTML={{ __html: description }}>
            //     </div>

            //     <div className='p-[8px_0px] flex justify-center items-center gap-[2px]'>
            //         <span className='font-[600] text-[20px] leading-[24px] max-[500px]:text-[16px]'>{price}</span>
            //         <span className='font-[600] text-[20px] leading-[24px]  max-[500px]:text-[16px]'>AZN</span>
            //     </div>
            // </div>

            <div className='flex flex-col  max-w-[300px] h-auto max-[380px]:w-[225px]'>
                <div className=' flex justify-center'>
                    <div className='flex justify-center items-center max-w-[244px] h-[64px]'>
                        <p className='font-[600] text-[20px] max-[500px]:text-[16px] leading-[24px] text-[#000] text-center'>
                            {products_title}
                        </p>
                    </div>
                </div>

                {/* gap 12 */}

                <div className='relative flex justify-center '>
                    <img src={products_image} className='rounded-[8px] w-[300px] h-[auto] max-[1000px]:w-[262.5px]  max-[870px]:w-[240px]  max-[500px]:w-[196.5px]  max-[380px]:w-[225px]' />
                    <span className='absolute bottom-[16px] left-[16px] bg-[#fff] p-[8px_16px] font-[300] text-[12px] leading-[20px] rounded-[4px] max-[500px]:p-[6px_12px]'>
                        {weight}
                    </span>
                </div>

                <div className='p-[0px_16px]  flex justify-center font-[400] text-[14px] leading-[20px] text-[#8D8D8D] text-center ' dangerouslySetInnerHTML={{ __html: description }}>
                </div>

                <div className='p-[8px_0px] flex justify-center items-center gap-[2px] '>
                    <span className='font-[600] text-[20px] leading-[24px] max-[500px]:text-[16px]'>{price}</span>
                    <span className='font-[600] text-[20px] leading-[24px] max-[500px]:text-[16px]'>AZN</span>
                </div>
            </div>
        )
    }
}

export default Card1