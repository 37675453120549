import React, { Component } from 'react'

export class InstagramSvg20 extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                fill="none"
            >
                <path
                    fill="#000"
                    d="M10 6.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Zm0 6.25a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm3.75-10.625h-7.5A4.38 4.38 0 0 0 1.875 6.25v7.5a4.38 4.38 0 0 0 4.375 4.375h7.5a4.38 4.38 0 0 0 4.375-4.375v-7.5a4.38 4.38 0 0 0-4.375-4.375Zm3.125 11.875a3.125 3.125 0 0 1-3.125 3.125h-7.5a3.125 3.125 0 0 1-3.125-3.125v-7.5A3.125 3.125 0 0 1 6.25 3.125h7.5a3.125 3.125 0 0 1 3.125 3.125v7.5ZM15 5.937a.938.938 0 1 1-1.875 0 .938.938 0 0 1 1.875 0Z"
                />
            </svg>
        )
    }
}

export default InstagramSvg20