import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import CatagoriesSections from '../components/CatagoriesSections'
import MenuButton from '../components/MenuButton'
import catagoryData, { getCatagories, getMenuHeaderImage } from '../actions/MainAction'
import CallButton from '../components/cards/CallButton'

export class Menu extends Component {
  sectionRefs = {};

  componentDidMount() {
    this.props.getCatagories();
    this.props.getMenuHeaderImage();

    window.scrollTo(0, 0);
  }

  scrollToSection = (id) => {
    const sectionRef = this.sectionRefs[id];
    if (sectionRef && sectionRef.current) {
      const offsetTop = sectionRef.current.offsetTop; // Bölümün sayfadaki konumu
      const headerHeight = 80; // Sabit header yüksekliği (header padding dahil)
      window.scrollTo({
        top: offsetTop - headerHeight, // Header yüksekliğini düş
        behavior: "smooth", // Yumuşak kaydırma
      });
    }
  };

  render() {
    const { catagoryData, menuImageData } = this.props;

    return (
      <main>
        <header>
          <div
            style={{
              backgroundImage: `url(${menuImageData?.[0]?.image_full_url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '16px',
              minHeight: '400px',
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="content-center"
          >
            {catagoryData?.map((data, i) => {
              const langCode = localStorage.getItem('langCode');
              return (
                <MenuButton
                  key={i}
                  id={data?.id}
                  icons={data?.icon_dark}
                  title={langCode === 'az' ? data?.title : data?.title_en}
                  onClick={() => this.scrollToSection(data?.id)}
                />
              );
            })}
          </div>
        </header>

        <section className="container pt-[60px] flex flex-col">
          {catagoryData?.map((data, i) => {
            if (!this.sectionRefs[data?.id]) {
              this.sectionRefs[data.id] = createRef();
            }
            const langCode = localStorage.getItem('langCode');

            return (
              <div
                key={i}
                ref={this.sectionRefs[data.id]}
                className="flex flex-col pt-[60px]"
              >
                <CatagoriesSections
                  key={i}
                  background_img={data?.background_img}
                  icon={data?.icon}
                  catagories_title={langCode === 'az' ? data?.title : data?.title_en}
                  id={data?.id}
                />
              </div>
            );
          })}
        </section>
        <CallButton />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  catagoryData: state.Data.catagoryData,
  menuImageData: state.Data.menuImageData,
});

const mapDispatchToProps = { getCatagories, getMenuHeaderImage };

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
