import React, { Component } from 'react'

export class MenuIconSvg extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M16.647 4.276a12.354 12.354 0 0 1 4.182-.174c.03.004.062.017.097.061a.394.394 0 0 1 .074.243v13.16c0 .287-.246.51-.502.501-1.219-.04-3.01-.003-4.699.405-1.16.28-2.089.749-2.799 1.23V6.466c0-.24.055-.362.095-.414.123-.16.502-.5 1.155-.868.63-.356 1.45-.7 2.397-.909Zm4.44-2.157a14.353 14.353 0 0 0-4.87.203c-1.156.255-2.16.674-2.95 1.12-.766.432-1.4.93-1.753 1.385C11.108 5.35 11 5.966 11 6.466V22a1 1 0 0 0 1.83.558l.002-.003-.002.002v.001l.002-.003.027-.037c.027-.037.073-.096.138-.171.13-.152.336-.37.62-.607a6.458 6.458 0 0 1 2.652-1.324c1.436-.347 3.02-.388 4.163-.35a2.503 2.503 0 0 0 2.568-2.5V4.406c0-1.057-.705-2.13-1.913-2.287Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7.353 4.276a12.354 12.354 0 0 0-4.182-.174c-.03.004-.062.017-.097.061A.394.394 0 0 0 3 4.406v13.16c0 .287.246.51.502.501 1.219-.04 3.01-.003 4.699.405 1.16.28 2.089.749 2.799 1.23V6.466c0-.24-.055-.362-.095-.414-.123-.16-.502-.5-1.155-.868-.63-.356-1.45-.7-2.397-.909Zm-4.44-2.157c1.09-.142 2.87-.238 4.87.203 1.156.255 2.16.674 2.95 1.12.766.432 1.4.93 1.753 1.385.406.524.514 1.139.514 1.639V22a1 1 0 0 1-1.83.558l-.002-.003.002.002v.001l-.002-.003a3.377 3.377 0 0 0-.165-.208 5.394 5.394 0 0 0-.62-.607 6.459 6.459 0 0 0-2.652-1.324c-1.436-.347-3.02-.388-4.163-.35A2.503 2.503 0 0 1 1 17.566V4.406c0-1.057.705-2.13 1.913-2.287Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default MenuIconSvg