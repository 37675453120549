import React, { Component } from 'react'

export class InstagramSvg2 extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={32}
                fill="none"
            >
                <path
                    fill={this.props.fill}
                    d="M16 10a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6Zm0 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm6-17H10a7.007 7.007 0 0 0-7 7v12a7.008 7.008 0 0 0 7 7h12a7.008 7.008 0 0 0 7-7V10a7.008 7.008 0 0 0-7-7Zm5 19a5 5 0 0 1-5 5H10a5 5 0 0 1-5-5V10a5 5 0 0 1 5-5h12a5 5 0 0 1 5 5v12ZM24 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                />
            </svg>
        )
    }
}

export default InstagramSvg2