import React, { Component } from 'react'

export class AboutUs extends Component {
    render() {
        const { image } = this.props;
        return (
            <div className='w-[670px]  p-[0px_18px] max-[1010px]:w-[556px] max-[942px]:w-[456px] 
             max-[682px]:w-[376px]  max-[484px]:w-[490px] 
            '>


                <img className='w-[634px] h-[auto]   max-[1010px]:w-[520px] 
                max-[942px]:w-[420px]  max-[682px]:w-[340px] max-[484px]:w-[280px] rounded-[16px] mb-[32px]' src={image} />
            </div>

        )
    }
}

export default AboutUs