import React, { Component } from 'react'
import logo from '../images/footerLogo.png'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'


export class Footer extends Component {
    getLangCode = () => {
        const langCode = localStorage.getItem('langCode');// Dili buradan alıyoruz
        return langCode === 'az' ? '/az' : '/ru';
    };
    render() {
        const langCode = localStorage.getItem('langCode');
        const { contentData } = this.props;

        const langCodeId = this.getLangCode();

        return (
            <footer className='pt-[72px] max-[1010px]:pt-[74px]'>
                <div className='p-[28px_68px_0px_68px] max-[1010px]:p-[26px_20px_0px_20px]'>
                    <div className='flex justify-between gap-[32px] p-[20px_0px] max-[768px]:justify-center'>
                        <div className='flex items-center gap-[32px]'>
                            <div className='max-[768px]:hidden'>
                                <img src={logo} className='w-[40px] h-[32px]' />
                            </div>
                            <ul className='flex items-center gap-[32px] max-[768px]:hidden'>
                                <li>
                                    <Link to={`${langCodeId}/`} className="font-[600] text-[16px] leading-[20px] text-[#8D8D8D]">
                                        {contentData?.nav_home_page}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${langCodeId}/menu`} className="font-[600] text-[16px] leading-[20px] text-[#8D8D8D]">
                                        {contentData?.nav_menu}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${langCodeId}/company`} className="font-[600] text-[16px] leading-[20px] text-[#8D8D8D]">
                                        {contentData?.nav_contact}
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className='flex items-center gap-[32px] max-[330px]:gap-[20px] max-[330px]:flex-col'>
                            <p className='font-[700] text-[16px] leading-[24px] text-[#000]'>{contentData?.working_house}</p>
                            <p className='font-[400] text-[16px] leading-[24px] text-[#000]'>{contentData?.clock}</p>
                        </div>

                        {/* <div className='flex items-center gap-[32px] max-[768px]:hidden'>
                            <Link to="https://www.instagram.com/maqqash.baku/" target='__blank'>
                                <InstagramSvg />
                            </Link>
                            <Link to="https://www.facebook.com/vanilla.az" target='__blank'>
                                <FacebookSvg />
                            </Link>
                            <Link to="https://wa.me/+994504905905" target='__blank'>
                                <WhatsappSvg width="24" height="24" />
                            </Link>
                        </div> */}

                    </div>
                </div>

                <div className='hidden bg-[#F5F5F5] p-[20px_0px]  max-[768px]:flex justify-center items-center gap-[12px]' >
                    <img src={logo} className='w-[40px] h-[32px]' />
                    <p className='font-[300] text-[12px] leading-[16px] text-[#000]'>
                        {contentData?.footer_right_reserved}
                    </p>
                </div>


                <div className='bg-[#F5F5F5] max-[768px]:hidden'>
                    <div className='p-[0px_68px] max-[1010px]:p-[0px_20px]'>
                        <div className='flex justify-between p-[16px_0px]'>
                            <p className='font-[300] text-[12px] leading-[16px] text-[#000]'>
                                {contentData?.footer_right_reserved}
                            </p>
                            {langCode === 'az' ?
                                <Link to="https://www.instagram.com/despro.az/" target='__blank' className='font-[300] text-[12px] leading-[16px] text-[#000]'>
                                    <span className='font-[600] text-[12px] leading-[16px] text-[#000]'>despro.az</span> tərəfindən hazırlanmışdır
                                </Link>
                                :
                                <Link to="https://www.instagram.com/despro.az/" target='__blank' className='font-[300] text-[12px] leading-[16px] text-[#000]'>
                                    Сделано в <span className='font-[600] text-[12px] leading-[16px] text-[#000]'>despro.az</span>
                                </Link>
                            }

                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
const mapStateToProps = (state) => ({
    contentData: state.Data.contentData,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Footer)