import React, { Component } from 'react'
import MenuIconSvg from '../icons/MenuIconSvg'
import { Link } from 'react-router-dom'

export class MenuBUtton extends Component {

  getLangCode = () => {
    const langCode = localStorage.getItem('langCode');// Dili buradan alıyoruz
    return langCode === 'az' ? '/az' : '/ru';
  };
  render() {
    const langCodeId = this.getLangCode();

    return (
      <Link to={`${langCodeId}/menu`} className='fixed bottom-[20px] left-[20px] z-[989] p-[16px] bg-[#666666] rounded-[50%] hidden max-[1010px]:block'>
        <MenuIconSvg />
      </Link>
    )
  }
}

export default MenuBUtton