import React, { Component } from 'react'

export class WhatsappSvg20 extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                fill="none"
            >
                <path
                    fill="#000"
                    d="m14.655 11.316-2.5-1.25a.626.626 0 0 0-.625.039l-1.148.765a3.168 3.168 0 0 1-1.25-1.25l.766-1.147a.625.625 0 0 0 .039-.625l-1.25-2.5A.625.625 0 0 0 8.125 5 3.125 3.125 0 0 0 5 8.125 6.883 6.883 0 0 0 11.875 15 3.124 3.124 0 0 0 15 11.875a.625.625 0 0 0-.345-.56Zm-2.78 2.434A5.631 5.631 0 0 1 6.25 8.125a1.875 1.875 0 0 1 1.507-1.84l.897 1.798-.763 1.136a.625.625 0 0 0-.057.586 4.411 4.411 0 0 0 2.355 2.356.624.624 0 0 0 .592-.052l1.142-.76 1.796.896a1.875 1.875 0 0 1-1.844 1.505ZM10 1.875a8.125 8.125 0 0 0-7.173 11.944l-.887 2.66A1.25 1.25 0 0 0 3.52 18.06l2.66-.887A8.125 8.125 0 1 0 10 1.875Zm0 15a6.865 6.865 0 0 1-3.442-.923.625.625 0 0 0-.511-.052l-2.922.975.974-2.922a.625.625 0 0 0-.051-.51A6.875 6.875 0 1 1 10 16.874Z"
                />
            </svg>
        )
    }
}

export default WhatsappSvg20