import React, { Component } from 'react'

export class ButtonHamburgerSvg extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                fill="none"
            >
                <path
                    fill="#000"
                    d="M.695 2.364A1.092 1.092 0 0 1 2.24.82l10.137 10.138L22.512.82a1.091 1.091 0 1 1 1.544 1.544L13.147 13.272a1.091 1.091 0 0 1-1.543 0L.695 2.364Zm21.817 9.365L12.376 21.867 2.239 11.729a1.09 1.09 0 0 0-1.78.354 1.092 1.092 0 0 0 .236 1.19L11.604 24.18a1.093 1.093 0 0 0 1.543 0l10.909-10.909a1.091 1.091 0 1 0-1.544-1.543Z"
                />
            </svg>
        )
    }
}

export default ButtonHamburgerSvg